<template>
  <div class="baseline-deployment-page">
    <header class="header">
      <div class="top-bar">
        <div class="container">
          <div class="logo">
            <img
              src="@/assets/optionsTechnology-logo.svg"
              alt="Options IT Logo"
            />
          </div>
        </div>
      </div>
      <div class="nav-bar" v-if="isLoggedIn">
        <div class="container">
          <NavBar />
        </div>
      </div>
    </header>

    <div class="deployment-container">
      <!-- Tab Navigation for Full or Custom Deployment -->
      <div class="tab-header">
        <button
          :class="{ 'active-tab': activeTab === 'onboard' }"
          @click="activeTab = 'onboard'"
        >
          On-Board Tenant
        </button>
        <button
          :class="{ 'active-tab': activeTab === 'full' }"
          @click="activeTab = 'full'"
        >
          Full Baseline Deployment
        </button>
        <button
          :class="{ 'active-tab': activeTab === 'custom' }"
          @click="activeTab = 'custom'"
        >
          Custom Baseline Deployment
        </button>
      </div>

      <div class="tab-content-window">
        <div v-if="activeTab === 'onboard'" class="tab-content">
          <!-- On-Board Tenant Form -->
          <div v-if="showOnboardForm" class="main-content">
            <h2 class="baseline-text">On-Board Client</h2>
            <p class="baseline-text">
              Please enter the clients information as required below:
            </p>
            <form @submit.prevent="handleSubmit" class="form">
              <div class="form-group">
                <label for="client-code">Client Code:</label>
                <input
                  type="text"
                  id="client-code"
                  v-model="clientCode"
                  @input="validateClientCode"
                  :class="[
                    'onboard-input',
                    {
                      'is-invalid':
                        invalidFields.includes('clientCode') || clientCodeError,
                    },
                  ]"
                  required
                  placeholder="abc"
                />
                <div class="validation-messages">
                  <span v-if="clientCodeError" class="error-message">{{
                    clientCodeError
                  }}</span>
                  <span v-if="clientCodeSuccess" class="success-message">{{
                    clientCodeSuccess
                  }}</span>
                </div>
              </div>
              <div class="form-group">
                <label for="csp-subscription"
                  >Does the tenant have an active subscription named "Options
                  IT" ?</label
                >
                <select
                  id="csp-subscription"
                  v-model="hasOptionsCSP"
                  class="onboard-input"
                >
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>

                <!-- Conditional input field for custom subscription name -->
                <div v-if="hasOptionsCSP === 'no'" class="subscription-input">
                  <label for="subscription-name"
                    >Enter Subscription Name:</label
                  >
                  <input
                    type="text"
                    id="subscription-name"
                    v-model="customSubscriptionName"
                    :class="[
                      'onboard-input',
                      {
                        'is-invalid':
                          invalidFields.includes('subscriptionName'),
                      },
                    ]"
                    required
                    placeholder="e.g CSP-OptionsIT-M365-Business"
                  />
                </div>
              </div>
              <div class="form-group">
                <label for="domain">Domain:</label>
                <input
                  type="text"
                  id="domain"
                  v-model="domain"
                  :class="[
                    'onboard-input',
                    { 'is-invalid': invalidFields.includes('domain') },
                  ]"
                  required
                  placeholder="example.com"
                />
              </div>
              <div class="form-group">
                <label for="username">Username:</label>
                <input
                  type="text"
                  id="username"
                  v-model="username"
                  :class="[
                    'onboard-input',
                    { 'is-invalid': invalidFields.includes('username') },
                  ]"
                  required
                  placeholder="admin@example.onmicrosoft.com"
                />
              </div>
              <div class="form-group">
                <label for="password">Password:</label>
                <input
                  type="password"
                  id="password"
                  v-model="password"
                  :class="[
                    'onboard-input',
                    { 'is-invalid': invalidFields.includes('password') },
                  ]"
                  required
                  placeholder="Enter secure password"
                />
              </div>
              <button
                type="submit"
                class="primary-button"
                :disabled="!isFormValid"
              >
                Submit
              </button>
            </form>
            <div v-if="formError" class="error-message">
              Please fill in all the required fields.
            </div>
            <!-- Updated message container for vertical stacking -->
            <div v-if="message.text" class="message" :class="message.type">
              {{ message.text }}
            </div>
            <div v-if="pipelineStatusMessage.text" class="message status-info">
              {{ pipelineStatusMessage.text }}
            </div>
          </div>

          <!-- Apply Baseline: Pipeline Dropdown and Run Button -->
          <div v-if="showPipelineList" class="pipeline-container">
            <label for="pipeline-select">Select Pipeline:</label>
            <select v-model="selectedPipelineId" id="pipeline-select">
              <option
                v-for="pipeline in pipelines"
                :key="pipeline.id"
                :value="pipeline.id"
              >
                {{ pipeline.name }} ({{ formatFolder(pipeline.folder) }})
              </option>
            </select>
            <button
              @click="runSelectedPipeline"
              class="primary-button"
              :disabled="!selectedPipelineId"
            >
              Run Pipeline
            </button>
            <div
              v-if="pipelineMessage.text"
              :class="['message', pipelineMessage.type]"
            >
              {{ pipelineMessage.text }}
            </div>
          </div>
        </div>

        <!-- Full Baseline Deployment -->
        <div v-if="activeTab === 'full'" class="tab-content">
          <h2 class="baseline-text">Full Baseline Deployment</h2>
          <p class="baseline-text">
            This will deploy the entire baseline configuration to the tenant.
          </p>
          <div class="pipeline-selection">
            <label for="pipeline-select-full">Select Pipeline:</label>
            <select v-model="selectedPipelineId" id="pipeline-select-full">
              <option
                v-for="pipeline in pipelines"
                :key="pipeline.id"
                :value="pipeline.id"
              >
                {{ pipeline.name }} ({{ formatFolder(pipeline.folder) }})
              </option>
            </select>
          </div>
          <button
            @click="deployFullBaseline"
            class="primary-button"
            :disabled="!selectedPipelineId"
          >
            Deploy Full Baseline
          </button>
          <div v-if="message.text" class="message" :class="message.type">
            {{ message.text }}
          </div>
          <div v-if="pipelineStatusMessage.text" class="message status-info">
            {{ pipelineStatusMessage.text }}
          </div>
        </div>

        <!-- Custom Deployment -->
        <div v-if="activeTab === 'custom'" class="tab-content">
          <h2 class="baseline-text">Custom Baseline Deployment</h2>
          <p class="baseline-text">
            Please select the required custom configuration for the client.
          </p>
          <div class="pipeline-selection">
            <label for="pipeline-select-custom">Select Pipeline:</label>
            <select v-model="selectedPipelineId" id="pipeline-select-custom">
              <option
                v-for="pipeline in pipelines"
                :key="pipeline.id"
                :value="pipeline.id"
              >
                {{ pipeline.name }} ({{ formatFolder(pipeline.folder) }})
              </option>
            </select>
          </div>
          <div class="global-buttons">
            <button @click="selectAll(true)" class="primary-button">
              Select All Options
            </button>
            <button @click="selectAll(false)" class="primary-button">
              Deselect All Options
            </button>
          </div>
          <div
            class="category-section"
            v-for="(options, category) in configurationCategories"
            :key="category"
          >
            <h3>
              {{ category }}
              <div class="category-buttons">
                <button
                  @click="toggleCategory(category, true)"
                  class="category-button"
                >
                  Select All
                </button>
                <button
                  @click="toggleCategory(category, false)"
                  class="category-button"
                >
                  Deselect All
                </button>
              </div>
            </h3>
            <div class="checkbox-group">
              <label v-for="option in options" :key="option">
                <input
                  type="checkbox"
                  v-model="selectedComponents"
                  :value="option"
                />
                {{ option }}
              </label>
            </div>
          </div>
          <button
            @click="deploySelectedComponents"
            class="primary-button"
            :disabled="selectedComponents.length === 0 || !selectedPipelineId"
          >
            Deploy Selected Components
          </button>
          <div v-if="message.text" class="message" :class="message.type">
            {{ message.text }}
          </div>
          <div v-if="pipelineStatusMessage.text" class="message status-info">
            {{ pipelineStatusMessage.text }}
          </div>
          <div
            v-if="deployedComponents.length > 0"
            class="deployed-components-section"
          >
            <h3>Deployed Components:</h3>
            <ul>
              <li v-for="component in deployedComponents" :key="component">
                {{ component }}
              </li>
            </ul>
          </div>
        </div>

        <div
          v-if="deploymentMessage.text"
          :class="['message', deploymentMessage.type]"
        >
          {{ deploymentMessage.text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/views/components/NavBar.vue";
import { msalInstance } from "@/auth.js";
import {
  fetchPipelinesWithFilterandProject,
  runFullBaselinePipeline,
  triggerOnboardBuild,
  getPipelineStatus,
  runCustomBaselinePipeline,
} from "@/azure-devops-api.js";
import { mapState } from "vuex";

export default {
  components: {
    NavBar,
  },
  data() {
    return {
      activeTab: "onboard",
      showOnboardForm: true,
      showPipelineList: false,
      selectedComponents: [],
      deployedComponents: [], // Store deployed components here
      deploymentMessage: { text: "", type: "" },
      pipelines: [],
      selectedPipelineId: null,
      pipelineMessage: { text: "", type: "" },
      clientCode: "",
      domain: "",
      username: "",
      password: "",
      formError: false,
      invalidFields: [],
      message: { text: "", type: "" },
      clientCodeError: "",
      clientCodeSuccess: "",
      clientCodeValidated: false,
      hasOptionsCSP: "yes",
      customSubscriptionName: "", // Holds user-entered subscription name
      pipelineStatusMessage: { text: "", type: "" }, // Initialize pipelineStatusMessage
      configurationCategories: {
        "Microsoft Intune Configuration Options": [
          "Create Device Compliance Policies",
          "Create Auto Pilot Profiles",
          "Create Managed App Policies",
          "Create iOS Apps",
          "Create Android Apps",
          "Create Deploy Intent Policies",
          "Create Device Management Configuration Policies",
          "Create Device Management Device Configurations Policies",
          "Create Device Management - Health Scripts",
          "Create Device Management - Management Scripts",
          "Create Conditional Access - Policies",
          "Create Conditional Access - Named Locations",
          "Create Device Clean-Up Rules",
          "Create Device Registration Policy Settings",
        ],
        "Exchange Online Configuration Options": [
          "Enable Organization Customisation",
          "Set Organization Configuration Compliance",
          "Set Anti Phishing Policy Compliance",
          "Set ATP Policy Compliance - Defender for Office 365",
          "Set Remote Domain Compliance",
          "Set Sharing Policy Compliance",
          "Set Malware Filter Policy Compliance",
          "Set Hosted Outbound Spam Filter Policy Compliance",
          "Set OWA Mailbox Policy Compliance",
          "Set Transport Rule Compliance",
          "Set Admin Audit Log Enabled",
          "Set Safe Links Policy",
        ],
        "SharePoint Configuration Options": [
          "Set SharePoint Configuration Compliance",
        ],
        "Azure AD Configuration Options": [
          "Create Azure AD Groups",
          "Set AAD Unified Group Settings",
          "Set AAD Consent Policy Settings",
          "Set AAD Password Rule Settings",
          "Set AAD User Consent Settings",
          "Set AAD Admin Consent Settings",
          "Set AAD Admin Center Settings",
        ],
        "Tenant Organisational Configuration Options": [
          "Set Microsoft Forms Organisational Settings",
          "Set Microsoft Teams Client Configuration Settings",
          "Set Microsoft Teams Tenant Federation Configuration Settings",
        ],
        "Azure AD Policy Definition Configuration Options": [
          "Set Azure AD Policy Baseline",
        ],
      },
    };
  },
  computed: {
  ...mapState(["isLoggedIn"]), 

  isFormValid() {
    return (
      this.clientCodeValidated && 
      this.clientCode.trim() !== "" &&
      this.domain.trim() !== "" &&
      this.username.trim() !== "" &&
      this.password.trim() !== "" &&
      (this.hasOptionsCSP === "yes" || this.customSubscriptionName.trim() !== "")
    );
  },
},

  methods: {
    async deployFullBaseline() {
      if (!this.selectedPipelineId) return;

      try {
        const accounts = msalInstance.getAllAccounts();
        if (accounts.length) {
          const accessTokenRequest = {
            account: accounts[0],
            scopes: ["499b84ac-1321-427f-aa17-267ca6975798/user_impersonation"],
          };
          const response = await msalInstance.acquireTokenSilent(
            accessTokenRequest
          );
          const accessToken = response.accessToken;

          // Retrieve the selected pipeline
          const selectedPipeline = this.pipelines.find(
            (p) => p.id === this.selectedPipelineId
          );
          if (!selectedPipeline) {
            throw new Error("Selected pipeline not found.");
          }
          const projectId = selectedPipeline._links.self.href.split("/")[4];

          // Trigger the pipeline and get the run ID
          const runId = await runFullBaselinePipeline(
            accessToken,
            projectId,
            this.selectedPipelineId
          );

          // Check if the pipeline run ID exists
          if (runId) {
            this.deploymentMessage.text = "Pipeline triggered successfully!";
            this.deploymentMessage.type = "success";

            // Start polling the pipeline status
            this.pollPipelineStatus(
              accessToken,
              projectId,
              this.selectedPipelineId,
              runId
            );
          } else {
            this.deploymentMessage.text = "Failed to trigger the pipeline.";
            this.deploymentMessage.type = "error";
          }
        } else {
          throw new Error("No accounts available for authentication.");
        }
      } catch (error) {
        console.error("Error during full baseline deployment:", error);
        this.deploymentMessage = {
          text: "Error during full baseline deployment: " + error.message,
          type: "error",
        };
      }
    },

    async deploySelectedComponents() {
      if (!this.selectedPipelineId) return;

      try {
        const accounts = msalInstance.getAllAccounts();
        if (accounts.length) {
          const accessTokenRequest = {
            account: accounts[0],
            scopes: ["499b84ac-1321-427f-aa17-267ca6975798/user_impersonation"],
          };
          const response = await msalInstance.acquireTokenSilent(
            accessTokenRequest
          );
          const accessToken = response.accessToken;

          // Retrieve the selected pipeline
          const selectedPipeline = this.pipelines.find(
            (p) => p.id === this.selectedPipelineId
          );
          if (!selectedPipeline) {
            throw new Error("Selected pipeline not found.");
          }
          const projectId = selectedPipeline._links.self.href.split("/")[4];

          // Convert selectedComponents array into a JSON string for the pipeline run
          const componentsJson = JSON.stringify({
            components: this.selectedComponents,
          });

          // Trigger the pipeline and get the run ID
          const runId = await runCustomBaselinePipeline(
            accessToken,
            projectId,
            this.selectedPipelineId,
            componentsJson
          );

          // Check if the pipeline run ID exists
          if (runId) {
            this.deploymentMessage.text =
              "Selected components have started deploying successfully. . .";
            this.deploymentMessage.type = "success";

            // Add selected components to the deployedComponents array
            this.deployedComponents = [...this.selectedComponents];

            // Start polling the pipeline status
            this.pollPipelineStatus(
              accessToken,
              projectId,
              this.selectedPipelineId,
              runId
            );
          } else {
            this.deploymentMessage.text = "Failed to trigger the pipeline.";
            this.deploymentMessage.type = "error";
          }
        } else {
          throw new Error("No accounts available for authentication.");
        }
      } catch (error) {
        console.error("Error during component deployment:", error);
        this.deploymentMessage = {
          text: "Error during component deployment: " + error.message,
          type: "error",
        };
      }
    },
    async fetchPipelines() {
      try {
        const accounts = msalInstance.getAllAccounts();
        if (accounts.length) {
          const accessTokenRequest = {
            account: accounts[0],
            scopes: ["499b84ac-1321-427f-aa17-267ca6975798/user_impersonation"],
          };
          const response = await msalInstance.acquireTokenSilent(
            accessTokenRequest
          );
          const projectName = "Aegis";
          this.pipelines = await fetchPipelinesWithFilterandProject(
            response.accessToken,
            projectName
          );
        }
      } catch (error) {
        console.error("Error fetching pipelines:", error);
      }
    },
    async handleSubmit() {
      if (!this.validateForm()) {
        this.formError = true;
        return;
      }

      try {
        const accounts = msalInstance.getAllAccounts();
        if (accounts.length) {
          const accessTokenRequest = {
            account: accounts[0],
            scopes: ["499b84ac-1321-427f-aa17-267ca6975798/user_impersonation"],
          };
          const response = await msalInstance.acquireTokenSilent(
            accessTokenRequest
          );
          const accessToken = response.accessToken;

          // Determine Subscription Name for $DefaultSubscriptionForLogin
          const subscriptionForLogin =
            this.hasOptionsCSP === "yes"
              ? "Options IT"
              : this.customSubscriptionName;

          const formData = {
            clientCode: this.clientCode,
            domain: this.domain,
            username: "*****",
            password: "*****",
            DefaultSubscriptionForLogin: subscriptionForLogin, // Pass correct value
          };

          console.log("Form submitted:", formData);

          const projectId = "1b238677-4a8d-4243-827e-88a0558c085a";
          const pipelineBuildId = "75";

          // Trigger the pipeline and get the run ID
          const runId = await triggerOnboardBuild(
            accessToken,
            projectId,
            pipelineBuildId,
            this.clientCode,
            this.domain,
            this.username,
            this.password,
            subscriptionForLogin // Pass it to the pipeline
          );

          if (runId) {
            this.message.text = "Pipeline triggered successfully!";
            this.message.type = "success";

            // Start polling the pipeline status
            this.pollPipelineStatus(
              accessToken,
              projectId,
              pipelineBuildId,
              runId
            );

            // Clear the fields
            this.clientCode = "";
            this.domain = "";
            this.username = "";
            this.password = "";
            this.hasOptionsCSP = "yes";
            this.customSubscriptionName = "";
            this.clientCodeValidated = false;
            this.formError = false;
            this.invalidFields = [];
          } else {
            this.message.text = "Failed to trigger the pipeline.";
            this.message.type = "error";
          }
        } else {
          throw new Error("No accounts available for authentication.");
        }
      } catch (error) {
        console.error("Error during form submission:", error);
        this.message.text = "Error triggering build: " + error.message;
        this.message.type = "error";
      }
    },
    // Add the pollPipelineStatus method
    async pollPipelineStatus(accessToken, projectId, pipelineId, runId) {
      const pollingInterval = 5000; // Poll every 5 seconds
      const successMessageDuration = 3000; // Show success message for 3 seconds before updating

      // Separate state for status updates
      this.pipelineStatusMessage = { text: "", type: "" };

      // Preserve the success message for a set duration
      setTimeout(() => {
        this.pipelineStatusMessage.text = ""; // Clear the success message
        checkStatus(); // Start polling after success message duration
      }, successMessageDuration);

      const checkStatus = async () => {
        try {
          console.log("Checking pipeline status...");
          const status = await getPipelineStatus(
            accessToken,
            projectId,
            pipelineId,
            runId
          );
          console.log("Pipeline status response: ", status);
          this.pipelineStatusMessage.text = `Pipeline status: ${
            status.state
          } - ${status.result || "In Progress"}`;
          this.pipelineStatusMessage.type =
            status.result === "succeeded" ? "success" : "info";

          // Stop polling if the pipeline is no longer running
          if (status.state !== "inProgress") {
            return;
          }

          // Continue polling
          setTimeout(checkStatus, pollingInterval);
        } catch (error) {
          console.error("Error checking pipeline status:", error);
          this.pipelineStatusMessage.text =
            "Error checking pipeline status: " + error.message;
          this.pipelineStatusMessage.type = "error";
        }
      };
    },
    selectAll(select) {
      if (select) {
        this.selectedComponents = [
          ...Object.values(this.configurationCategories).flat(),
        ];
      } else {
        this.selectedComponents = [];
      }
    },
    toggleCategory(category, select) {
      if (select) {
        this.selectedComponents = [
          ...new Set([
            ...this.selectedComponents,
            ...this.configurationCategories[category],
          ]),
        ];
      } else {
        this.selectedComponents = this.selectedComponents.filter(
          (item) => !this.configurationCategories[category].includes(item)
        );
      }
    },
    formatFolder(folder) {
      return folder.replace("\\", "").trim();
    },
    validateForm() {
      this.invalidFields = [];
      if (!this.clientCode) this.invalidFields.push("clientCode");
      if (!this.domain) this.invalidFields.push("domain");
      if (!this.username) this.invalidFields.push("username");
      if (!this.password) this.invalidFields.push("password");
      return this.invalidFields.length === 0;
    },
    validateClientCode() {
      const clientCodePattern = /^[A-Z]{3}$/;
      if (!clientCodePattern.test(this.clientCode)) {
        this.clientCodeError =
          "Client code must be exactly 3 uppercase letters.";
        this.clientCodeSuccess = "";
        this.clientCodeValidated = false;
      } else {
        this.clientCodeError = "";
        this.clientCodeSuccess = `Client Code is valid!`;
        this.clientCodeValidated = true;
      }
    },
  },

  // Watcher to refresh pipelines when the activeTab changes
  watch: {
    activeTab() {
      this.fetchPipelines(); // Fetch pipelines whenever activeTab changes
    },
  },

  mounted() {
    this.fetchPipelines(); // Fetch pipelines when the component is mounted
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&family=Roboto:wght@400;500;700&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.baseline-deployment-page {
  font-family: "Roboto", sans-serif;
}

.container {
  width: 100%;
  margin: 0 auto;
  font-family: "Roboto", sans-serif;
}

.header {
  background: #0e223b;
  color: #fff;
}

.top-bar {
  background: #ffffff;
  padding: 20px 0;
  text-align: left;
}

.top-bar .logo img {
  width: 150px;
  margin-left: 20px;
}

.nav-bar {
  background: #0e223b;
  padding: 10px 0;
}

.nav-bar a {
  color: #fff;
  margin: 0 15px;
  text-decoration: none;
  transition: color 0.3s;
}

.nav-bar a:hover {
  color: #38b35f;
}

.deployment-container {
  max-width: 900px;
  margin: 40px auto;
  padding: 2rem;
  background-color: #f8f8f8; /* Subtle background color */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Drop shadow for depth */
  font-family: "Roboto", sans-serif;
}

/* Tab header */
.tab-header {
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid #ddd;
  margin-bottom: 2rem;
}

.tab-header button {
  background-color: #f0f0f0;
  color: #333;
  padding: 12px 25px;
  border: none;
  font-size: 1rem;
  margin: 0 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-bottom: 2px solid transparent;
  border-radius: 5px 5px 0 0;
}

.tab-header button.active-tab {
  border-bottom: 2px solid #1e90ff;
  background-color: #fff;
  font-weight: bold;
}

.tab-header button:hover {
  background-color: #e0e0e0;
}

.tab-content-window {
  width: 100%;
  max-width: 800px;
  margin: 40px 0 40px 20px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-family: "Roboto", sans-serif;
}

/* Form */
.main-content {
  background-color: #fff;
  padding: 30px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  max-width: 600px;
  margin: 0 auto;
  font-family: "Roboto", sans-serif;
}

form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  font-family: "Roboto", sans-serif;
}

.form-group {
  margin-bottom: 20px;
}

label {
  font-size: 1.1rem;
  color: #333;
  font-weight: 600; /* Set all labels to bold */
  margin-bottom: 5px;
  display: block; /* Ensure labels are above inputs */
}

.onboard-input {
  width: 100%;
  padding: 12px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s ease;
  background-color: #fafafa;
  font-family: "Roboto", sans-serif;
}

.onboard-input:focus {
  border-color: #1e90ff;
  background-color: #fff;
  outline: none;
}

.is-invalid {
  border-color: #dc3545 !important;
}

.is-invalid:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25) !important;
}

/* Submit Button */
.primary-button {
  padding: 12px 30px;
  font-size: 1.1rem;
  border: none;
  border-radius: 8px;
  background-color: #1e90ff;
  color: white;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s;
  font-family: "Roboto", sans-serif;
}

.primary-button:hover {
  background-color: #1c7ed6;
  transform: translateY(-2px); /* Adds a lift effect */
}

.primary-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Error and success messages */
.error-message {
  color: #dc3545;
  font-size: 0.9rem;
  font-family: "Roboto", sans-serif;
}

.success-message {
  color: #28a745;
  font-size: 0.9rem;
  font-family: "Roboto", sans-serif;
}

.message {
  margin-top: 20px;
  padding: 15px;
  border-radius: 8px;
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
}

.message.success {
  background-color: #d4edda;
  color: #155724;
  font-family: "Roboto", sans-serif;
}

.message.error {
  background-color: #f8d7da;
  color: #721c24;
  font-family: "Roboto", sans-serif;
}

/* Other Styling */
.global-buttons {
  display: flex;
  justify-content: flex-start;
  gap: 15px;
  margin-bottom: 30px;
}

.pipeline-selection {
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 15px;
  margin-bottom: 30px;
  font-family: "Roboto", sans-serif;
}

#pipeline-select,
#pipeline-select-full,
#pipeline-select-custom {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  max-width: 600px;
  flex-grow: 1;
}

.checkbox-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  margin-top: 15px;
  margin-bottom: 20px;
  font-family: "Roboto", sans-serif;
}

button {
  font-family: "Roboto", sans-serif;
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #e0e0e0;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.category-section {
  margin-bottom: 30px;
}

.category-buttons {
  display: inline-block;
  margin-left: 15px;
}

.category-button {
  font-size: 0.8rem;
  padding: 5px 10px;
  margin-right: 10px;
  border: 1px solid #1e90ff;
  color: #1e90ff;
  background-color: #fff;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.category-button:hover {
  background-color: #1e90ff;
  color: #fff;
}

.deployed-components-section {
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  color: #333;
  margin-top: 20px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.deployed-components-section h3 {
  font-size: 1.1rem;
  font-weight: bold;
  color: #1c7ed6;
  margin-bottom: 10px;
}

.deployed-components-section ul {
  list-style-type: disc;
  padding-left: 20px;
}

.deployed-components-section li {
  margin-bottom: 5px;
}

.baseline-text {
  font-family: "Roboto", sans-serif;
  color: #333;
  font-weight: 500;
  margin-bottom: 1rem;
}

.message.status-info {
  background-color: #cce5ff; /* Light blue background */
  border-color: #1c7ed6; /* Border color */
  color: #1c7ed6; /* Text color */
  font-family: "Roboto", sans-serif;
}

.subscription-input {
  margin-top: 15px; /* Increase spacing for clarity */
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 8px; /* Increase spacing between label & input */
}

.subscription-input label {
  font-weight: 500; /* Make label slightly bolder for readability */
  color: #333;
}

.subscription-input input {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s ease;
  background-color: #fafafa;
}

.subscription-input input:focus {
  border-color: #1e90ff;
  background-color: #fff;
  outline: none;
}

.validation-messages {
  margin-top: 5px;
}
</style>
